@use 'reset-css';
@use './var';
@use './mixin';
@import './global';



body {
    height: 100%;
    width: 100%;
    font-size: 1.2rem;
    background-image:  url("./icones/orange-background.webp");
    // linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),

    // l'image sera fixe et le contenu defilera 
    // par dessus: parallax.
    background-attachment: fixed;

    // indique que l'image doit être centrée à la fois horizontalement
    //  et verticalement dans le conteneur.
    background-position: center;

    // Ajuste l'image pour qu'elle couvre tout le conteneur
    background-size: cover;

}

